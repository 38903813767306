
//Form Generator is used to create and handle a form with set of fields and validations.

import { defineComponent, ref, watch } from "vue";
import InputField from "./input-field.vue";
import SelectField from "./select-field.vue";
import EditorField from "./editor-field.vue";
import Fileupload from "./file-upload.vue";
import TextareaField from "./textarea-field.vue";

export default defineComponent({
  name: "FormGenerator",
  components: {
    InputField,
    SelectField,
    EditorField,
    Fileupload,
    TextareaField,
  },
  props: {
    schema: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    submitButton: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props) {
    var submitted = ref(false);
    var formValid = false;
    var formData = props.schema || [];
    var errorMessages: any = ref({});

    watch(errorMessages.value, () => {
      //   console.log("");
    });
    watch(submitted, () => {
      //   console.log("");
    });
    watch(props.schema, () => {
      formData = props.schema;
    });
    return { formData, errorMessages, submitted, formValid };
  },

  methods: {
    updateForm(fieldName: any, value: any) {
      var foundIndex = this.formData.findIndex((x: any) => x.name == fieldName);
      this.formData[foundIndex].value = value;
      if (this.submitted) {
        this.validate(fieldName, value, foundIndex);
      }
    },
    validate(fieldName: any, value: any, index: any) {
      let errorsList: any = [];
      if (this.formData[index].validators) {
        this.formData[index].validators.forEach((element: any) => {
          element.value = value;
          let error = element.validator(element);
          if (error == true) {
            errorsList.push(element.message);
          }
          this.errorMessages[`${fieldName}`] = errorsList[0];
        });
      }
    },
    onSubmitted() {
      this.submitted = true;
      this.formData.forEach((element: any, index: any) => {
        if (element.validators && element.validators.length) {
          this.validate(element.name, element.value, index);
        }
      });
      const proxy1 = new Proxy(this.errorMessages, {});
      const errorValues = JSON.parse(JSON.stringify(proxy1));
      if (Object.keys(errorValues).length === 0) {
        this.formValid = true;
        this.$emit("updatedForm", this.formData);
      } else {
        return;
      }
    },
    resetForm(reset = true) {
      this.submitted = false;
      if (reset) {
        this.errorMessages = {};
        this.formData.forEach((field: any) => {
          (this.$refs[`${field.name}`] as any)[0].resetForm();
          field.value = field.defaultValue;
        });
      }
    },
    iconsClicked(data: any) {
      this.$emit("iconClicked", data);
    },
    resourceChanged(data: any) {
      var foundIndex = this.formData.findIndex(
        (field: any) => field.name == data.field.name
      );
      if (data.field.multi) {
        this.formData[foundIndex].value = data.selected.map(
          (e: any) => e[data.field.changedFieldKey]
        );
      } else {
        this.formData[foundIndex].value = data.resource
          ? data.resource[data.field.changedFieldKey]
          : null;
      }
    },
    removeErrorMessages(field_names: any) {
      field_names.forEach((field_name: any) => {
        this.errorMessages[field_name] = undefined;
      });
    },
  },
});
