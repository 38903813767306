import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "float-start pb-2" }
const _hoisted_2 = ["type", "name", "placeholder", "disabled", "value"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.field.label), 1),
    _createElementVNode("div", {
      class: _normalizeClass({ 'd-flex': _ctx.field.icons })
    }, [
      _createElementVNode("input", {
        class: "form-control mt-3",
        type: _ctx.field.type,
        name: _ctx.field.name,
        placeholder: _ctx.field.placeholder,
        disabled: _ctx.field.disabled,
        value: _ctx.field.value,
        autocomplete: "off",
        ref: "inputFieldValue",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('bindValue', $event.target.value.trim())))
      }, null, 40, _hoisted_2),
      (_ctx.field.icons)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.field.icon_class)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.icons, (icon, index) => {
              return (_openBlock(), _createElementBlock("a", {
                class: "text-dark",
                role: "button",
                key: index,
                onClick: ($event: any) => (_ctx.onClickIcon(_ctx.field, icon.name))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(icon.class)
                }, null, 2)
              ], 8, _hoisted_3))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}