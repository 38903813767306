
//Input field is reusable component will be used to generate form.

import { defineComponent } from "vue";

export default defineComponent({
  name: "TextareaField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resetForm() {
      (this.$refs["textareaField"] as any).value = this.field.defaultValue;
    },
  },
});
