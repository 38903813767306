
//This is a Text editor component to format the text.

import { defineComponent } from "vue";
export default defineComponent({
  name: "EditorField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      context: this.field.value || "",
    };
  },
  watch: {
    async context(newValue) {
      this.$emit("bindValue", newValue.trim());
    },
  },
  methods: {
    resetForm() {
      this.context = "";
    },
  },
});
