import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "float-start pb-2" }
const _hoisted_2 = ["name", "placeholder", "rows", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.field.label), 1),
    _createElementVNode("div", {
      class: _normalizeClass({ 'd-flex': _ctx.field.icons })
    }, [
      _createElementVNode("textarea", {
        class: "form-control mt-3",
        name: _ctx.field.name,
        placeholder: _ctx.field.placeholder,
        rows: _ctx.field.rows,
        value: _ctx.field.value,
        autocomplete: "off",
        ref: "textareaField",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('bindValue', $event.target.value.trim())))
      }, "\r\nEnter text here...", 40, _hoisted_2)
    ], 2)
  ]))
}