import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34ad615d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "float-start" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  type: "text",
  class: "form-select text-start ps-2 multi-select d-flex"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  class: "typeahead-select",
  style: {"display":"contents"}
}
const _hoisted_7 = {
  key: 0,
  class: "list-group"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { tabindex: "-1" }
const _hoisted_10 = {
  key: 1,
  class: "position-relative"
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "typeahead-select" }
const _hoisted_13 = {
  key: 0,
  class: "list-group"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { tabindex: "-1" }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = ["value"]
const _hoisted_18 = { value: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.field.label), 1),
    (_ctx.field.multi)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (value, i) => {
              return (_openBlock(), _createElementBlock("span", {
                key: i,
                class: "col-lg-1 col-md-1 col-sm-2 col-xs-2 bg-light me-2"
              }, [
                _createElementVNode("span", null, _toDisplayString(value[_ctx.field.selectedField]), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close fsp-10 ms-1",
                  onClick: ($event: any) => (_ctx.remove(i)),
                  "aria-label": "Close"
                }, null, 8, _hoisted_4)
              ]))
            }), 128)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
              placeholder: !_ctx.items.length ? _ctx.field.placeholder : '',
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args))),
              onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
              ref: "selectFieldValue",
              role: "button"
            }, null, 40, _hoisted_5), [
              [_vModelText, _ctx.query]
            ]),
            (_ctx.items.length)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn-close fsp-10 ms-1 mt-2",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.removeAll && _ctx.removeAll(...args))),
                  "aria-label": "Close"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.focused && !_ctx.hasSelected)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resourceQueryResults, (resource, i) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "list-group-item",
                      role: "button",
                      key: i,
                      onClick: ($event: any) => (_ctx.selectSearchResource(resource))
                    }, [
                      _createElementVNode("a", _hoisted_9, [
                        _createElementVNode("span", null, _toDisplayString(resource[_ctx.field.selectedField]) + " " + _toDisplayString(resource[_ctx.field.changedField]), 1)
                      ])
                    ], 8, _hoisted_8))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_ctx.field.isResource)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.query) = $event)),
              class: "form-control",
              onFocus: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
              onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
              onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
              placeholder: _ctx.field.placeholder,
              ref: "selectFieldValue",
              autocomplete: "off",
              onInput: _cache[9] || (_cache[9] = ($event: any) => (
          _ctx.field.acceptAll
            ? _ctx.$emit('bindValue', $event.target.value.trim())
            : null
        ))
            }, null, 40, _hoisted_11), [
              [_vModelText, _ctx.query]
            ]),
            _createElementVNode("div", _hoisted_12, [
              (_ctx.focused && !_ctx.hasSelected)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resourceQueryResults, (resource, i) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "list-group-item",
                        role: "button",
                        key: i,
                        onClick: ($event: any) => (_ctx.selectResource(resource))
                      }, [
                        _createElementVNode("a", _hoisted_15, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.selectOptions(resource)), 1)
                        ])
                      ], 8, _hoisted_14))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("select", {
              class: "form-select",
              value: _ctx.field.value,
              autocomplete: "off",
              ref: "selectFieldValue",
              onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('bindValue', $event.target.value.trim())))
            }, [
              _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.field.placeholder), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.options, (option) => {
                return (_openBlock(), _createElementBlock("option", { key: option }, _toDisplayString(option), 1))
              }), 128))
            ], 40, _hoisted_17)
          ]))
  ]))
}