
//Fileupload field is reusable component will be used to generate form.

import { defineComponent } from "vue";

export default defineComponent({
  name: "Fileupload",
  data() {
    return {
      files: [],
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resetForm() {
      (this.$refs["file"] as any).value = this.field.defaultValue;
      this.files = [];
      console.log("file reset");
    },
    chooseFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    uploadfile() {
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        this.files.push(this.$refs.file.files[i]);
      }
      this.$emit("bindValue", this.files);
    },
    removeFile(index: any) {
      if (this.files.length == 1) {
        this.files = [];
      } else {
        let filterFiles = [];
        for (var i = 0; i < this.$refs.file.files.length; i++) {
          if (i != index) {
            filterFiles.push(this.$refs.file.files[i]);
          }
        }
        this.files = filterFiles;
      }
    },
  },
});
