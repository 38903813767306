import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "text-danger float-start mt-2"
}
const _hoisted_4 = { class: "col-md-12 text-center" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData, (field, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(field.class)
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(field.fieldType), _mergeProps({
            onBindValue: ($event: any) => (_ctx.updateForm(field.name, $event))
          }, { field: field }, {
            emailValid: field.invalid,
            ref_for: true,
            ref: field.name,
            onIconsClicked: _ctx.iconsClicked,
            onResourceChanged: _ctx.resourceChanged
          }), null, 16, ["onBindValue", "emailValid", "onIconsClicked", "onResourceChanged"])),
          (_ctx.errorMessages[field.name])
            ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.errorMessages[field.name]), 1))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        class: _normalizeClass(_ctx.submitButton.class),
        type: "submit",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmitted && _ctx.onSubmitted(...args))),
        value: 
          _ctx.submitted && _ctx.formValid ? _ctx.submitButton.loading : _ctx.submitButton.value
        
      }, null, 10, _hoisted_5)
    ])
  ]))
}