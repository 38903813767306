
//Select field is reusable component will be used to generate form.

import { defineComponent } from "vue";

export default defineComponent({
  name: "SelectList",
  props: {
    field: {
      type: Object,
      required: true,
    },
    deselectTimeOut: {
      default: 500,
    },
  },
  data: function () {
    return {
      loading: true,
      query: "",
      focused: false,
      hasSelected: false,
      resourceQueryResults: [],
      resource: {},
      items: [],
      hasSearched: false,
    };
  },
  watch: {
    async query(value, newValue) {
      if (newValue || (value && value.length == 1)) {
        this.getOptions(value);
      }
    },
  },
  async mounted() {
    this.query = this.field.defaultValue;
    this.loading = false;
  },
  methods: {
    onSearch() {
      this.getOptions(this.query);
    },
    resetForm() {
      (this.$refs["selectFieldValue"] as any).value = this.field.defaultValue;
      this.query = this.field.defaultValue;
    },
    onFocus() {
      this.getOptions();
      this.focused = true;
      this.hasSelected = false;
      this.$refs.selectFieldValue.focus();
      this.$refs.selectFieldValue.select();
    },
    onBlur() {
      setTimeout(() => {
        this.focused = false;
        const serachValue = this.selectOptions(this.resource);
        if (
          !this.hasSelected &&
          !this.field.multi &&
          !this.field.acceptAll &&
          serachValue != this.query
        ) {
          this.query = this.field.defaultValue;
        }
      }, this.deselectTimeOut);
    },
    selectResource(resource: any) {
      this.hasSelected = true;
      this.resource = resource;
      this.query = this.selectOptions(resource);
      this.$emit("resourceChanged", { resource, field: this.field });
    },
    selectSearchResource(resource: any) {
      (this.$refs["selectFieldValue"] as any).value = "";
      this.hasSelected = true;
      this.resource = resource;
      this.items.push(resource);
      this.$emit("resourceChanged", {
        resource,
        field: this.field,
        selected: this.items,
      });
    },
    onChange() {
      this.$emit("resourceChanged", { field: this.field });
    },
    async getOptions(value = "") {
      if (
        (this.field.resource && !this.field.search) ||
        (this.field.resource && this.field.search && value.length)
      ) {
        let queryParams: any = {
          page: 1,
          page_size: this.field.page_size,
        };
        queryParams[
          this.field.search ? "search" : `${this.field.changedField}__contains`
        ] = value || "";
        let result = await this.field.resource.wrap("/", queryParams).get();
        if (result) {
          this.resourceQueryResults = result.data.result;
          this.hasSearched = true;
        }
      }
    },
    remove(index: any) {
      this.items.splice(index, 1);
      this.$emit("resourceChanged", {
        field: this.field,
        selected: this.items,
      });
    },
    removeAll() {
      this.items = [];
      this.$emit("resourceChanged", {
        field: this.field,
        selected: this.items,
      });
    },
    selectOptions(item: any) {
      if (typeof this.field.changedField == "string") {
        return item[this.field.changedField];
      } else {
        return `${this.field.changedField
          .map((field: any) => item[field])
          .join(" ")}`;
      }
    },
  },
});
